<template>
  <div id="firfinFoot" class="foot_firfin">
    <div class="inner_foot">
      <div class="area_right">
        <h3 class="screen_out">서비스 메뉴</h3>
        <div class="area_menu">
          <strong class="tit_menu"><router-link :to="$routerPath.BLOG" class="link_menu_tit">블로그</router-link></strong>
          <ul class="list_menu">
            <li><router-link :to="$routerPath.NOTICE_LIST" class="link_menu">공지&amp;이벤트</router-link></li>
            <li><router-link :to="$routerPath.POST_LIST" class="link_menu">포스트</router-link></li>
            <li><router-link :to="{ name:'Blog', query:{section:'letter'} }" class="link_menu">퍼핀레터</router-link></li>
          </ul>
        </div>
        <div class="area_menu">
          <strong class="tit_menu"><router-link :to="$routerPath.COMPANY" class="link_menu_tit">회사소개</router-link></strong>
          <ul class="list_menu">
            <li><router-link :to="{ name:'Company', query:{section:'intro'} }" class="link_menu">소개</router-link></li>
            <li><router-link :to="{ name:'Company', query:{section:'press'} }" class="link_menu">언론보도</router-link></li>
            <li><router-link :to="{ name:'Company', query:{section:'support'} }" class="link_menu">제휴문의</router-link></li>
          </ul>
        </div>
        <div class="area_menu">
          <strong class="tit_menu"><router-link :to="$routerPath.HELP" class="link_menu_tit">고객센터</router-link></strong>
        </div>
      </div>
      <div class="area_left">
        <h3 class="tit_company">(주) 레몬트리</h3>
        <ul class="list_trem">
          <li><a href="https://service.lemontree.ai/term/firfin-pay-terms" target="_blank" class="link_term">서비스 이용약관</a></li>
          <li><a href="https://service.lemontree.ai/term/firfin-money-terms" target="_blank" class="link_term">퍼핀머니 이용약관</a></li>
          <li><a href="https://service.lemontree.ai/term/electronic-financial-terms" target="_blank" class="link_term">전자금융거래 이용약관</a></li>
          <li><a href="https://service.lemontree.ai/term/privacy-policy" target="_blank" class="link_term">개인정보 처리방침</a></li>
        </ul>
        <div class="info_company">
          <dl>
            <dt>사업자번호</dt>
            <dd>887-86-02559</dd>
            <span class="txt_dot">·</span>
            <span class="txt_break_response"><dt>대표</dt>
            <dd>이민희 </dd></span>
          </dl>
          <dl>
            <dt>대표전화</dt>
            <dd>
              <a href="tel:031-726-9990">031-726-9990</a>
            </dd>
          </dl>
          <dl>
            <dt>투자 및 제휴 문의</dt>
            <dd>
              <a href="mailto:hello@lemontree.ai">hello@lemontree.ai</a>
            </dd>
          </dl>
          <dl>
            <dt class="screen_out">주소</dt>
            <dd>경기도 성남시 분당구 성남대로331번길 8 507호</dd>
          </dl>
        </div>
        <p class="desc_copy">Copyright &copy; Lemontree Inc. All rights reserved.</p>
        <h3 class="screen_out">레몬트리 SNS</h3>
        <ul class="list_sns">
          <li>
            <a class="link_sns" href="https://www.facebook.com/firfin.family" target="_blank">
              <IconSvg
                iconName="facebook"
                iconColor="#232323"
                bgcolor="#8F69DD"
                :size="36"/>
              <span class="screen_out">facebook</span>
            </a>
          </li>
          <li>
            <a class="link_sns" href="https://blog.naver.com/firfin" target="_blank">
              <IconSvg
              iconName="blog"
              iconColor="#232323"
              bgcolor="#8F69DD"
              :size="36"/>
              <span class="screen_out">blog</span>
            </a>
          </li>
          <li>
            <a class="link_sns" href="https://www.instagram.com/firfin.family" target="_blank">
              <IconSvg
              iconName="instagram"
              iconColor="#232323"
              bgcolor="#8F69DD"
              :size="36"/>
              <span class="screen_out">instagram</span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg.vue';
import { mapGetters, mapActions } from 'vuex';

export default{
  name:'Footer',
  components:{
    IconSvg,
  },
  mounted(){
    this.setFootHeight()
  },
  methods:{
    ...mapActions('commonViewport', [ 'fetchViewPort' ]),
    setFootHeight(){
      this.$nextTick(() => {
        const footEl = document.getElementById('firfinFoot');
        const footHeight = footEl ? footEl.offsetHeight : null;
        if(!footHeight) return
        this.fetchViewPort({ footHeight: footHeight });
      });
    }
  }
}
</script>

<style scoped>
.foot_firfin{position:absolute;bottom:0;left:0;right:0;font-size:14px;line-height:16px;background-color:#232323;color:#fff}
.foot_firfin .inner_foot{overflow:hidden;padding:120px 0}
/* left */
.tit_company{display:block;font-weight:700;font-size:18px;line-height:28px}
/* trem{ */
.list_trem{overflow:hidden;margin-top:24px}
.list_trem li{float:left}
.list_trem li + li{margin-left:24px}
.list_trem li .link_term{display:block;font-weight:800;font-size:16px;line-height:24px;color:#8F69DD}
/* info */
.info_company{margin-top:24px}
.info_company dl{overflow:hidden;margin-top:2px}
.info_company dt,
.info_company dd,
.info_company dd a,
.info_company .txt_dot{float:left;font-weight:600;font-size:16px;line-height:24px;color:#999}
.info_company dd{margin-left:4px}
.info_company .txt_dot{margin:0 4px}
.info_company .screen_out + dd{margin-left:0}
/* copy */
.desc_copy{margin-top:24px;font-weight:500;font-size:14px;line-height:22px;color:#666}
/* sns */
.list_sns{overflow:hidden;margin-top:24px}
.list_sns li{float:left}
.list_sns li + li{margin-left:24px}
.list_sns .icon_firfin{border-radius:100%}

/* right */
.area_menu{float:left;width:160px}
.area_menu + .area_menu{margin-left:48px}
.area_menu .tit_menu{display:block}
.area_menu .tit_menu .link_menu_tit{display:block;font-weight:700;font-size:18px;line-height:28px;color:#8F69DD}
.area_menu .list_menu{margin-top:24px}
.area_menu .list_menu li + li{margin-top:8px}
.area_menu .list_menu .link_menu{display:block;font-weight:600;font-size:16px;line-height:24px;color:#fff}

/* 모바일 */
@media all and (max-width:1199px){
  .foot_firfin{font-size:14px;line-height:16px;box-sizing:border-box}
  .foot_firfin .inner_foot{padding:60px 32px 120px 32px}
  /* left */
  .foot_firfin .area_left{float:none;padding-left:0}
  .tit_company{margin-top:32px}
  /* trem{ */
  .list_trem li{float:none}
  .list_trem li + li{margin:8px 0 0 0}
  /* info */
  .info_company{word-wrap:break-word;word-break:keep-all}
  .info_company .txt_dot{margin:0 4px}
  .info_company .screen_out + dd{margin-left:0}
  /* copy */
  .desc_copy{letter-spacing:-0.2px}

  /* right */
  .foot_firfin .area_right{float:none;padding-right:10px}
  .area_menu{float:none;width:auto}
  .area_menu + .area_menu{margin:32px 0 0 0}
}
</style>