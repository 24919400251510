<template>
  <div id="firfinHead" :class="['head_firfin',{ scrolled : isScrolled },{ active_gnb : isGnbOpen }]">
    <div class="inner_head">
      <h1 class="tit_logo">
        <router-link :to="$routerPath.MAIN">
          <IconSvg
            iconName="logo"
            iconColor="#232323"
            :size="getIsMobile ? 54 : 80"/>
          <span class="screen_out">Firfin</span>
        </router-link>
      </h1>
      <h2 class="screen_out">서비스 메뉴</h2>
      <Gnb/>
      <div class="area_mobile_gnb">
        <button class="btn_gnb" @click="onClickGnb()">
          <IconSvg
            v-if="isGnbOpen"
            iconName="cross"
            iconColor="#fff"
            :size="24"/>
          <IconSvg
            v-else
            iconName="menu"
            iconColor="#232323"
            :size="24"/>
          <span class="screen_out">{{ isGnbOpen ? '메뉴 닫기' : '메뉴 열기' }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Gnb from './Gnb.vue';
import IconSvg from '@/components/common/icon/IconSvg.vue';

import { mapGetters } from 'vuex';

export default{
  name:'Header',
  components:{
    Gnb,
    IconSvg,
  },
  computed:{
    ...mapGetters('commonViewport', [ 'getIsMobile' ]),
  },
  props:{
    isScrolled: Boolean
  },
  data(){
    return{
      isGnbOpen: false
    }
  },
  methods:{
    onClickGnb(){
      this.isGnbOpen = !this.isGnbOpen
    }
  }
}
</script>

<style scoped>
.head_firfin{position:fixed;top:0;left:0;right:0;z-index:100;height:80px;transition:background-color 0.8s ease-out}
.head_firfin.scrolled{background-color:rgba(255,255,255,.9);backdrop-filter:blur(4px);}
.head_firfin .inner_head{height:80px}

/* logo */
.head_firfin .tit_logo{float:left}
/* .head_firfin .tit_logo img{display:block;width:80px;height:80px} */

.area_mobile_gnb{display:none}

/* 모바일 */
@media all and (max-width:1199px){
  .head_firfin{height:54px;transition:background-color 0.2s ease}
  .head_firfin .inner_head{height:54px}
  /* .head_firfin .tit_logo img{width:54px;height:54px} */

  .area_mobile_gnb{display:block;float:right}
  .btn_gnb{margin:15px 0}
  .head_firfin .tit_logo,
  .head_firfin .area_mobile_gnb{position:relative;z-index:300}
  .head_firfin .tit_logo .icon_firfin >>> g,
  .head_firfin .area_mobile_gnb .icon_firfin >>> g{transition:fill 0.2s ease}
  .head_firfin >>> .gnb_firfin{transition:max-height 0.2s, background-color 0.2s ease}
  .head_firfin >>> .gnb_firfin .list_gnb,
  .head_firfin >>> .gnb_firfin .desc_copy{transition:opacity 0.2s ease}

  .head_firfin.active_gnb{background-color:#232323;backdrop-filter:inherit}
  .head_firfin.active_gnb .tit_logo .icon_firfin >>> g,
  .head_firfin.active_gnb .area_mobile_gnb .icon_firfin >>> g{fill:#fff}
  .head_firfin.active_gnb >>> .gnb_firfin{max-height:100%;background-color:#232323}
  .head_firfin.active_gnb >>> .gnb_firfin .list_gnb,
  .head_firfin.active_gnb >>> .gnb_firfin .desc_copy{opacity:1}
}
</style>