<template>
  <div id="firfinContent" class="cont_firfin" :style="cssProps">
    <div id="mArticle">
      <slot />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default{
  name:'Content',
  computed:{
    ...mapGetters('commonViewport', [ 'getFooterHeight' ]),
    cssProps(){
      let rObj = {
        '--footHeight' : `${this.getFooterHeight}px`
      }
      return rObj
    }
  },
}
</script>

<style scoped>
.cont_firfin{position:relative;height:100%;min-height:100vh;padding-bottom:var(--footHeight);box-sizing:border-box}
.cont_firfin #mArticle{overflow:hidden}

/* 모바일 */
/* @media all and (max-width:1199px){
  .cont_firfin{padding-bottom:860px}
} */
</style>