<template>
  <div id="firfinGnb" role="navigation" :class="['gnb_firfin']">
    <div class="inner_gnb">
      <ul class="list_gnb">
        <li>
          <router-link :to="$routerPath.BLOG" class="link_gnb">블로그</router-link>
          <div class="drop_box">
            <ul class="list_snb">
              <li :class="{ active : subMenu === 'NOTICE' }">
                <router-link :to="$routerPath.NOTICE_LIST" class="link_snb">공지&amp;이벤트
                <IconSvg
                  iconName="arrow_right"
                  iconColor="#BEA9ED"
                  :size="24"/></router-link>
              </li>
              <li :class="{ active : subMenu === 'POST' }">
                <router-link :to="$routerPath.POST_LIST" class="link_snb">포스트
                <IconSvg
                  iconName="arrow_right"
                  iconColor="#BEA9ED"
                  :size="24"/></router-link>
              </li>
            </ul>
          </div>
        </li>
        <li>
          <router-link :to="$routerPath.COMPANY" class="link_gnb">회사소개</router-link>
        </li>
        <li>
          <router-link :to="$routerPath.HELP" class="link_gnb">고객센터</router-link>
        </li>
      </ul>
      <p class="desc_copy">Copyright &copy; firfin Inc. All rights reserved.</p>
    </div>
  </div>
</template>

<script>
import IconSvg from '@/components/common/icon/IconSvg.vue';

export default{
  name:'Gnb',
  components:{
    IconSvg,
  },
  computed:{
    activeMenu(){
      return this.$route.meta.menuId
    },
    subMenu(){
      return this.$route.meta.subMenuId
    }
  }
}
</script>

<style scoped>
.gnb_firfin{float:right}
.gnb_firfin .list_gnb{display:inline-block;vertical-align:top}
.gnb_firfin .list_gnb > li{float:left;position:relative;}
.gnb_firfin .list_gnb > li + li{padding-left:32px}
.gnb_firfin .list_gnb .link_gnb{display:block;padding:25px 0;height:80px;font-weight:700;font-size:20px;line-height:30px;box-sizing:border-box}
.wrap_main .gnb_firfin .list_gnb li:hover .link_gnb{color:#fff}
.wrap_main .scrolled .gnb_firfin .list_gnb li:hover .link_gnb,
.gnb_firfin .list_gnb .link_gnb.router-link-active,
.gnb_firfin .list_gnb li:hover .link_gnb{color:#8F69DD}
.gnb_firfin .list_gnb li:hover .drop_box{display:block}

.drop_box{display:none;position:absolute;left:-246px;top:100%;padding:32px 20px;border-radius:4px;background-color:#fff;box-shadow:30px 30px 90px rgba(0, 0, 0, 0.24);}
.list_snb .link_snb{display:block;position:relative;width:360px;padding:14px 20px;border-radius:4px;font-weight:700;font-size:20px;line-height:28px;box-sizing:border-box}
.list_snb .link_snb .icon_arrow_right{position:absolute;top:16px;right:24px;opacity:0;transition: opacity 0.5s ease-out;}
.list_snb .active .link_snb{color:#8F69DD}
.list_snb .link_snb:hover{background-color:#F0EAFA}
.list_snb .link_snb:hover .icon_arrow_right{opacity:1}
.list_snb .active .link_snb:hover{background:none;cursor:default}
.list_snb .active .link_snb:hover .icon_arrow_right{opacity:0}

.desc_copy{display:none;position:absolute;bottom:48px;left:20px;right:20px;font-weight:500;font-size:14px;line-height:22px;color:#666}

/* 모바일 */
@media all and (max-width:1199px){
  .gnb_firfin{float:none;overflow:hidden;position:fixed;top:0;bottom:0;left:0;right:0;z-index:200;max-height:0px}
  .gnb_firfin .inner_gnb{height:100%;padding-top:102px;padding-bottom:80px;box-sizing:border-box;}
  .gnb_firfin .list_gnb{display:block;overflow-y:auto;max-height:100%;opacity:0}
  .gnb_firfin .list_gnb > li{float:none;position:static}
  .gnb_firfin .list_gnb > li + li{padding:0}
  .gnb_firfin .list_gnb .link_gnb{padding:16px 20px;height:auto;line-height:24px;color:#8F69DD}
  .wrap_main .gnb_firfin .list_gnb li:hover .link_gnb,
  .wrap_main .scrolled .gnb_firfin .list_gnb li:hover .link_gnb,
  .gnb_firfin .list_gnb .link_gnb.router-link-active,
  .gnb_firfin .list_gnb li:hover .link_gnb{color:#8F69DD}

  .drop_box{display:block;position:static;padding:0;border-radius:0;background:none;box-shadow:none}
  .list_snb .link_snb{display:block;position:static;width:auto;padding:16px 40px;border-radius:0;font-size:16px;line-height:24px;box-sizing:border-box;color:#fff}
  .list_snb .link_snb .icon_arrow_right{display:none}
  .list_snb .active .link_snb{color:#fff}
  .list_snb .active .link_snb,
  .list_snb .link_snb:hover{background:none}

  .desc_copy{display:block;opacity:0;}
}
</style>