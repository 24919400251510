const scrolled = {
  mounted(){
    this.scrollTop = 0;
    this.bindEventScroll();
    window.addEventListener('scroll', this.onScroll);
    window.onload = (e) =>{
      this.bindBodyHeight();
    };
  },
  beforeDestroy(){
    window.addEventListener('scroll', this.onScroll);
    window.onload = (e) =>{
      this.bindBodyHeight();
    };
  },
  updated(){
    this.bindEventScroll();
  },
  data(){
    return{
      isScrolled:false,
      scrollTop:0,
      bodyHeight:0
    }
  },
  methods: {
    bindEventScroll(){
      this.$nextTick(() => {
        const winT = window.scrollY;
        const setIsScrolled = winT > 0;
        this.isScrolled = setIsScrolled;
        this.scrollTop = winT;
      });
    },
    onScroll() {
      this.bindEventScroll();
    },
    bindBodyHeight(){
      this.bodyHeight = document.body.offsetHeight;
    }
  }
}
export default scrolled;