<template>
  <div class="inner_app">
    <div id="firfinWrap" ref="wrapfirfin" :class="['wrap_firfin', pageClassName]">
      <Header
        :isScrolled="isScrolled"/>
      <Content>
        <slot />
      </Content>
      <Footer/>
    </div>
    <slot name="popup" />
  </div>
</template>

<script>
import Header from '@/components/layout/header/Header';
import Content from '@/components/layout/content/Content';
import Footer from '@/components/layout/footer/Footer';
import Scrolled from '@/mixins/Scrolled'
import { camelToSnake } from '@/utils/stringUtils'

export default {
  name:'PageWithLayout',
  mixins:[ Scrolled ],
  components:{
    Header,
    Content,
    Footer
  },
  mounted(){
    this.setNaverScript();
  },
  computed:{
    pageClassName(){
      return `wrap_${camelToSnake(this.$route.name)}`;
    }
  },
  methods:{
    setNaverScript(){
      if(!wcs_add) var wcs_add = {};
      window.wcs_add = { wa : 's_eb4532faab1' };
      // // 애널리틱스 NA
      // if(window.wcs) wcs_do();
      // // 프리미엄 로그 분석
      if (!_nasa) var _nasa={};
      if(window.wcs){
        wcs.inflow("firfin.family");
        wcs_do(_nasa);
      }
    }
  },
}
</script>

<style>
.wrap_firfin{position:relative;min-height:100%}

.wrap_firfin,
.head_firfin,
.gnb_firfin{padding-top:env(safe-area-inset-top)}
.wrap_main .area_download .btn_download,
.foot_firfin{padding-bottom:env(safe-area-inset-bottom)}

.head_firfin .inner_head,
.cont_firfin .inner_section,
.foot_firfin .inner_foot{max-width:1280px;margin:0 auto;padding-left:20px;padding-right:20px}

@media (orientation: landscape) {
	.wrap_firfin .head_firfin .inner_head,
  .wrap_firfin .cont_firfin .inner_section,

  #firfinGnb.gnb_firfin .list_gnb .link_gnb,
  #firfinGnb.gnb_firfin .list_snb .link_snb,

  .wrap_main.wrap_firfin .section_top .cont_top,

  .wrap_main.wrap_firfin .section_partner .tit_section,
  .wrap_main.wrap_firfin .section_partner .desc_section,

  .wrap_notice_detail.wrap_firfin .view_comm,
  .wrap_post_detail.wrap_firfin .view_comm,

  .wrap_firfin .foot_firfin .inner_foot{padding-left:calc(20px + env(safe-area-inset-left));padding-right:calc(20px + env(safe-area-inset-right))}

  .wrap_main.wrap_firfin .section_review .swiper-review-button-prev{left:calc(20px + env(safe-area-inset-left))}
  .wrap_main.wrap_firfin .section_review .swiper-review-button-next{right:calc(20px + env(safe-area-inset-right))}

  #channelTalkBtn{right:calc(20px + env(safe-area-inset-right))}
}
/* .head_firfin .inner_head,
.cont_firfin .inner_section,
.foot_firfin .inner_foot{background-color:rgba(255,0,0,0.1)} */
.wrap_main{background-color:#BEA9ED}
.wrap_blog{background-color:#F1EEE1}
.wrap_notice_list,
.wrap_post_list{background-color:#F7F6FA}
</style>